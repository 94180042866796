import UAParser from 'ua-parser-js'
params = new URL(location.href).searchParams

# Find the host by using pattern match on the source
detect_host = (segment) ->
  script    = document.querySelector 'script[src*="' + segment + '"]'
  link      = document.createElement 'a'
  link.href = script.getAttribute 'src' if script
  link

# Simple JSON fetch
fetch_json = (url) ->
  headers = 'Content-Type': 'application/json'
  remote = await fetch url, { headers }
  do remote.json

# Read remote config
fetch_config = (url = null) ->
  url or= params.get('config') or document.querySelector('[data-config]').dataset.config
  fetch_json url

# Load config
load_settings = (url, logger) ->
  try
    json = await fetch_json url
    logger?.debug 'Loaded: ' + url
    json
  catch error
    logger?.error 'Can not load remote config: ' + error
    false

# Store console logs somewhere to send/save
capture_console = ->
  console.data = []
  [ 'warn', 'debug', 'error', 'info', 'log' ].forEach (action) ->
    old = console[action]
    if old
      console[action] = (...args) ->
        old ...args
        items = args.filter (item) -> 'string' is typeof item
        console.data.push [ new Date, action, ...items ]

# Tries to auto switch delivery based on browser ablity.
# Firefox doesn't have PeerConnection.setConfiguration
detect_compatiblity = (text = window.navigator.userAgent) ->
  unsupported = [ 'Amaya', 'iCab', 'NetSurf', 'Presto', 'Tasman', 'NetFront', 'Links', 'Lynx', 'Trident', 'w3m' ]
  AudioCtx = window.AudioContext or window.webkitAudioContext
  agent = do (new UAParser text).getResult
  return false if agent.browser.engine in unsupported or not AudioCtx
  return 'web_sockets' if agent.engine.name in [ 'EdgeHTML', 'Gecko', 'KHTML' ]
  'webrtc'

# See: https://github.com/github/fetch/issues/175
# Not used right now
timeout_promise = (wait_ms, promise) =>
  new Promise (resolve, reject) =>
    timer = setTimeout =>
      reject new Error 'Timeout'
    , wait_ms
    ok = (data) => resolve data
    fail = (error) => reject error
    promise.then(ok, fail).finally ->
      clearTimeout timer

invert = (object) ->
  inverted = Object.entries(object).map ([k, v]) -> [v, k]
  Object.fromEntries inverted

dom_ready = (callback) ->
  if document.readyState in [ 'interactive', 'complete' ]
    do callback
  else
    document.addEventListener 'DOMContentLoaded', callback

export {
  detect_compatiblity
  timeout_promise
  capture_console
  load_settings
  fetch_config
  detect_host
  dom_ready
  invert
}