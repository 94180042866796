import { capture_console, detect_compatiblity, dom_ready } from '../common/util'
import WebRtcPlayer from './lib/player'

Object.assign WebRtcPlayer, { detect_compatiblity }
# Export to window
Object.assign window, {
  capture_console
  WebRtcPlayer
}
# Emit an event because this will load typically with async
ready = new CustomEvent 'audio_ready',
  cancelable: true
  bubbles: true
dom_ready started = ->
  document.dispatchEvent ready
# Also export to ES modules
export {
  capture_console
  WebRtcPlayer
}
