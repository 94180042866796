import { Player, PLAYER_EVENTS } from '@flussonic/flussonic-webrtc-player'
import FlussonicMsePlayer from '@flussonic/flussonic-mse-player'
import EventEmitter from 'eventemitter3'

{ PLAY, FAIL } = PLAYER_EVENTS
DEBUG = true
AudioCtx = window.AudioContext or window.webkitAudioContext

register_events = (audio) ->
  events = new EventEmitter
  volume = audio.volume

  audio.current_level = (amplitude) ->
    events.emit 'current_level', { amplitude }

  warn = (message) ->
    console.warn  String(new Date) + ': AUDIO - ' + message

  playing = (_event) ->
    warn 'PLAYING'
    events.emit 'playing'

  stopped = (_event) ->
    warn 'STOPPED'
    events.emit 'finished'

  failed = (_event) ->
    warn 'FAILED'
    events.emit 'failed'

  self = { events, playing, stopped, failed, volume }

# Generic audio output that is wrapped
export AudioOutput = ->
  context   = new AudioCtx
  analyser  = null
  audio     = new Audio
  input     = context.createMediaElementSource audio
  analyser  = do input.context.createAnalyser
  gain_node = do context.createGain
  analyser.fftSize = 512
  audio.crossOrigin = 'anonymous'
  buffer = new Uint8Array analyser.fftSize
  input.connect analyser # This doesn't seem to work on WebRTC. Security context? createMediaStreamSource ??
  input.connect gain_node
  gain_node.connect context.destination

  analyse_sound = ->
    return unless analyser and !! analyser.getByteTimeDomainData
    analyser.getByteTimeDomainData buffer
    amplitude = playing_level buffer
    setTimeout ->
      self.current_level amplitude
    , 1
    window.requestAnimationFrame analyse_sound

  logarithm = (x) ->
    if 0 isnt x then Math.log(x) / Math.LN10 else 0

  # Accept an Uint8Array of PCM volumes
  playing_level = (pcm) ->
    sum = pcm.reduce (total, sample) ->
      total += Math.abs((sample - 128) / 128.0)
    , 0
    average = sum / pcm.length # Will be 0 - 1 range based on deviation from base 128 level
    logarithm 10 * average

  stop = ->
    do audio.pause
    do context.close unless 'closed' is context.state
    analyse_sound = ->
    audio.src = ''
    analyser = null

  volume = (level) ->
    gain_node.gain.value = level if gain_node
    audio.volume = level # Firefox needs gain node, Chrome needs this. Ergh

  current_level = (amplitude) ->
    console.error 'NOT IMPLEMENTED'

  setTimeout analyse_sound, 100

  self = {
    analyse_sound
    playing_level
    current_level
    context
    volume
    audio
    stop
  }

# HTTP(S) static files
export Http = (url) ->
  inner     = do AudioOutput
  stop      = inner.stop
  { audio } = inner

  self = {
    ...register_events(inner)
    playback
    inner
    stop
  }

  audio.onplaying = -> do self.playing
  audio.onended   = -> do self.stopped
  audio.src       = url
  playback = do audio.play
  playback.catch (issue) -> console.error issue
  playback.then -> do self.playing
  self

# Flussonic WebRTC
# See: https://github.com/flussonic/Flussonic-WebRTC-Demo-App/blob/master/src/index.js
export Webrtc = (url, { ice_servers }) ->
  console.log new Date
  console.log url
  token = url.match(/token=(\w+)/)[1] # Newer client needs this pulled out manually, albeit undocumented as on Jun 2022
  # url = 'https://webrtc.newsquawk.com/live/audio_test?token=SentanaECB'
  inner = do AudioOutput
  # This needs to auto play for WebRTC
  inner.audio.setAttribute 'autoplay', true
  inner.audio.getVideoPlaybackQuality = () -> new Object # Flussonic Ticket #165040
  stream = new Player inner.audio, url.replace(/\??token=\w+/, ''),
    authTokenName: 'token', 
    tokenValue: token, 
    statsSendEnable: false, # Makes loads of pointless requests. Also see: getVideoPlaybackQuality
    whipwhap: ! url.match /webrtc\d?\.newsquawk/ # After 23.01 this is needed
    onTrackInfo: -> do inner.audio.play
  , DEBUG
  window.stream = stream
  manual_stop = false # Flag to set if stop was expected or not

  # Note this method MUST be defined after stream assignment, or it will
  # silently continue to stream
  stop = ->
    manual_stop = true
    do self.events.removeAllListeners # Prevent ghost events
    if self.stream
      do self.stream.stop
      # do self.stream.destroy
      self.stream = null
    if inner
      do inner.stop
      inner = null

  # Note there is no 'finished' as these are constant streams, but we could
  # inspect for ws_closed / ice_connection_closed / peer_connection_closed ???
  # media = inner.context.createMediaStreamSource stream.remoteVideo.srcObject
  stream.on PLAY, (_event) ->
    do self.playing
  stream.on FAIL, (_event) ->
    do self.failed unless manual_stop
    do self.stopped
  # Reconfigure to use our own coturn instance - causes issues in FF some times
  # Note in later versions stream.remotePc is not exposed
  # stream.oldCreatePeerConnection = stream.createPeerConnection.bind stream
  # stream.createPeerConnection = =>
  #   do stream.oldCreatePeerConnection
  #   if stream.remotePc and stream.remotePc.setConfiguration
  #     stream.remotePc.setConfiguration iceServers: ice_servers

  self = {
    ...register_events(inner)
    stream
    stop
  }

  do inner.audio.play
  do stream.play
  stream.postStats = -> # This is an issue if the token is still in the URL
  self

# Flussonic MSE-LD (AAC)
export WebSocket = (url) ->
  inner     = do AudioOutput
  stall     = false
  started   = false
  playback  = null
  clear     = -> clearTimeout stall if stall
  stream    = new FlussonicMsePlayer inner.audio, url.replace(/^http/, 'ws'),
    debug: true
    statsSendEnable: false
    maxBufferDelay: 2.5
    wsReconnect: true
    connectionRetries: 3
    onMediaInfo: (media_info) ->
      started = true
      do inner.audio.play
    onStartStalling: ->
      # Small stalls happen at the start so give a 5 sec buffer
      stall = setTimeout ->
        do self.failed
      , 5000
    onEndStalling: -> do clear
    onError: () -> do self.failed
    onDisconnect: () -> do self.failed
  inner.audio.getVideoPlaybackQuality = () -> new Object # Flussonic Ticket #165040

  window.poop = stream

  stop = ->
    do self.events.removeAllListeners # Prevent ghost events
    do clear
    do inner.stop if started
    do stream.stop if stream # Note this throws a ' DOMException: The play() request was interrupted' if playback never started, due to calling inner.audio.load
    stream = null

  # Adding some delay generally prevents WS ready state warning
  setTimeout ->
    playback = do stream.play
    playback.then ->
      console.warn 'MSE LD PLAYING ' + url
      do self.playing
    playback.catch (issue) ->
      console.error issue
      do self.failed
  , 100

  self = {
    ...register_events(inner)
    playback
    stream
    inner
    stop
  }

# Note Andriod MSE https://github.com/flussonic/mse-player/blob/1321271e56f9686cf87f1fc74b7a6628ecee0039/src/utils/mseUtils.js#L15
# If one uses "request desktop site" it gets past this
OK = !! AudioCtx
RtcPeer = window.RTCPeerConnection or window.webkitRTCPeerConnection
WebSocket.supported = OK and !! do FlussonicMsePlayer.isSupported
Webrtc.supported = OK and !! RtcPeer
Http.supported = OK
