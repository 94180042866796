import { detect_compatiblity }  from '../../common/util'
import retry_playback           from './retry_playback'
import EventEmitter             from 'eventemitter3'
import UAParser                 from 'ua-parser-js'
import Cookies                  from 'js-cookie'
import * as playback            from './playback'

# Advances URL list to the next item
pick_item = (urls) ->
  offset = -1

  next = ->
    picker.offset += 1
    urls[picker.offset % urls.length]

  picker = { next, offset }

# Tries to gracefully fall back to web sockets when webrtc fails or using Firefox
# delivery should be webrtc/web_sockets to force protocol, or null to detect
protocol_detection = (delivery, timeout = 30000) ->
  agent   = do (new UAParser).getResult
  started = new Date
  
  last_protocol = (item) -> Cookies.set 'audio_delivery', item, expires: 4/24

  webrtc_succeeded  = -> last_protocol 'webrtc' if do recent
  webrtc_failure    = -> last_protocol 'web_sockets' if do recent
  webrtc_started    = -> started = new Date
  recent            = -> new Date - started < timeout

  detected = ->
    # return 'web_sockets'  if agent.engine.name is 'Gecko' # Not needed on 22.12
    selected = Cookies.get 'audio_protocol'
    return delivery       if delivery in [ 'web_sockets', 'webrtc' ]
    return selected       if selected in [ 'web_sockets', 'webrtc' ]
    return 'webrtc'       if playback.Webrtc.supported
    return 'web_sockets'  if playback.WebSocket.supported
    'none'

  self = { webrtc_failure, webrtc_succeeded, webrtc_started, detected, delivery }
  window.detect = self
  self

window.audio_pro = => Cookies.get 'audio_protocol'

# Player that wraps the state of playlists etc
export default webrtc_player = ({ channels, initial_state, ice_servers, delivery }) ->
  # FF has issues with WebRTC circa 22.08 unless whiphap is used. However Whipwhap 
  # does not transmit media info changes, which causes issues with fallback media
  detection     = protocol_detection delivery
  detected      = do detect_compatiblity
  audio         = null
  retry         = null
  status        = null
  clearing      = null
  webrtc_ok     = null
  events        = new EventEmitter
  state = {
    supported:    (if 'web_sockets' is delivery then playback.WebSocket.supported else playback.Webrtc.supported)
    last_proto:   do detection.detected
    status:       'stopped'
    volume:       100
    single:       false
    active:       false
    expanded:     false
    max_retries:  6
    offset:       0
    ...initial_state
  }
  console.info "Audio Delivery: Detected #{detected}, Using #{state.last_proto}, Support: #{state.supported}, cookie: #{Cookies.get 'audio_protocol'}"
  unless channels
    throw Error 'No channels'
  unless do detection.detected in [ 'web_sockets', 'webrtc' ]
    throw Error 'Bad delivery method'

  # This is a temp way to dirtily log 3rd party requests in case a partner is embedding badly
  # unless window.location.href.match /newsquawk.com/
  #   try
  #     fetch 'https://audio.newsquawk.com/management/ping?from=' + encodeURIComponent(window.location.href), method: 'GET'
  #   catch issue

  # Get the playlist based on the active channel
  current_playlist = ->
    channels.find (channel) -> channel.label is state.active

  # Mark an item as ready and remove any retry attempts after a min of stablity
  mark_as_ready = ->
    state.status = 'playing'
    tries = if retry then retry.attempts else 0
    clearing = window.setTimeout ->
      do retry.clear if retry and tries <= retry.attempts
      clearing = null
    , 20 * 1000
    webrtc_ok = setTimeout detection.webrtc_succeeded, 5000 if 'webrtc' is state.last_proto
    do refresh

  # Basic failed warning when disconnections happen
  mark_as_failed = ->
    state.status = 'failed' unless 'stopped' is state.status
    clearTimeout clearing if clearing
    clearTimeout webrtc_ok if webrtc_ok
    do detection.webrtc_failure if 'webrtc' is state.last_proto and retry.attempts > 1
    do stop_stream
    do refresh
    detected = do detect_compatiblity

  # Render the template markup, use self in case a newer template is used
  # Returns React like DOM tree
  refresh = ->
    events.emit 'refresh'

  toggle_menu = (_event) ->
    state.expanded = ! state.expanded
    do refresh

  prevent_retry = ->
    return unless retry
    do retry.clear
    retry.restart = ->
    retry = null

  # This will refresh the UI
  stop_audio = (_event) ->
    do prevent_retry
    do stop_stream
    Object.assign state,
      status: 'stopped'
      expanded: false
      active: false
      offset: 0
    do refresh

  # This version doesn't refresh the UI
  stop_stream = ->
    do audio.stop if audio
    audio = null

  # Setup a stream to play - retrying a few go if there is a connection failure
  play_stream = (start) ->
    retry = retry_playback ->
      sound = do start
      change_volume state.volume
      register sound.events
      sound
    , state.max_retries, self

  # Play using WebRTC
  play_webrtc = (urls) ->
    state.last_proto = 'webrtc'
    url = convert_legacy do urls.next
    start = -> audio = playback.Webrtc url, { ice_servers }
    do detection.webrtc_started
    play_stream start

  # Play using Flussonics MSE-LD WebSockets (More firewall friendly)
  play_web_sockets = (urls) ->
    state.last_proto = 'web_sockets'
    start = -> audio = playback.WebSocket do urls.next
    play_stream start

  # Auto detect  
  play_auto = (urls) ->
    start = -> 
      do detection.webrtc_started
      state.status = 'connecting'
      state.last_proto = do detection.detected
      sockets = state.last_proto is 'web_sockets'
      url = do urls.next
      url = convert_to_web_sockets url if sockets
      audio = if sockets
        playback.WebSocket url
      else
        playback.Webrtc url, { ice_servers }
    play_stream start

  # Play a basic file located on HTTPS/HTTP
  play_http = (urls) ->
    state.last_proto = 'http'
    audio = playback.Http do urls.next
    register audio.events

  # Call play next but also mutate the platlist status
  advance_playlist = ->
    list = do current_playlist
    if list and state.offset <= list.stream.length - 1
      state.offset += 1
      do play_next

  # Reset playlist state and stop any existing audio before restarting
  play_channel = (label, offset = 0) ->
    console.warn 'Play channel ~ ' + label
    do stop_audio
    state.active = label
    state.offset = offset
    do play_next

  # Find the next item in the playlist and dispatch based on delivery
  play_next = ->
    playlist = do current_playlist
    list = playlist.stream[state.offset]
    return unless list
    do prevent_retry
    do mark_connecting
    list = Array.of list unless Array.isArray list
    urls = switch
      when do detection.detected is 'web_sockets'
        list.map convert_to_web_sockets
      else list.map convert_legacy
    play_item urls if urls.length > 0
    change_volume state.volume
    do refresh

  # Play item - this is an array because if it fails it moves to the next stream
  play_item = (urls) ->
    list = pick_item urls
    switch
      when urls[0].match /\/mse_ld/
        play_web_sockets list
      when urls[0].match /^(ws|http)s?:\/\//
        # play_webrtc list
        play_auto list
      else
        play_http list

  # Some old setups use pseudo live-https to denote live WebRTC streams
  convert_legacy = (url) ->
    url.replace /^(ws|live-http)s?/, 'https'

  # Map WebRTC URL's into MSE LD
  convert_to_web_sockets = (url) ->
    convert_legacy(url).replace('?', '/mse_ld?')

  # Update volume state and notify the audio delivery
  change_volume = (level) ->
    state.volume = level
    audio.volume state.volume / 100 if audio
    do refresh

  mark_connecting = ->
    console.warn 'CONNECTING'
    state.status = 'connecting'
    do refresh

  # Bind audio delivery events
  register = (events) ->
    events.on 'playing', mark_as_ready
    events.on 'failed', mark_as_failed
    events.on 'finished', advance_playlist
    # events.on 'current_level', current_level # TODO: External

  # Reveal interface
  self = {
    current_playlist
    change_volume
    play_channel
    stop_stream
    toggle_menu
    stop_audio
    delivery # Externally mutable, if desired
    channels
    refresh
    events
    audio
    state
  }
  events.on 'failed_restart', failed_restart = ->
    # NOTE: when state.last_proto is 'webrtc' we should warn users to switch to web_sockets
    console.error 'Audio connection failure. Please retry later'
  # Initialize
  play_channel state.active, state.offset if state.active
  events.emit 'ready'
  events.on 'restart_setup', mark_connecting
  window.detection = detection
  do refresh
  self
