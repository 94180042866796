import { small, text } from 'teact'

export default ConnectionState = ({ active, status }) ->
  return null unless active
  switch status
    when 'connecting'
      small '.connection.connecting', ->
        text 'Connecting'
    when 'failed'
      small '.connection.failed', ->
        text 'Failed'
    else '' 