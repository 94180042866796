import { detect_compatiblity }  from '../../common/util'
import webrtc_player            from './webrtc_player'
import { renderer }             from '@bikeshaving/crank/dom'
import PlayerWidget             from '../components/player_widget'
import { crel }                 from 'teact'

# Wrap an existing host DOM node
export default WebRtcPlayer = ({ selector, channels, initial_state, ice_servers, delivery }) ->
  detected      = do detect_compatiblity
  root          = document.querySelector selector
  data          = root.dataset or {}
  return false unless root
  defaults      = initial_state or data.initialState or {}
  delivery    or= data.delivery or detected
  ice_servers or= if data.iceServers then JSON.parse data.iceServers else []
  channels    or= if data.channels then JSON.parse data.channels else []
  player        = webrtc_player { channels, initial_state: defaults, ice_servers, delivery }
  widget        = crel PlayerWidget, { player }

  # Use this in Turbolinks
  reattach = (root) ->
    root.parentNode.replaceChild player.root, root

  renderer.render widget, root
  Object.assign player, { reattach, widget, root }
  player