import { a, div, span } from 'teact'

export default StatusIcons = ({ player }) ->
  self = @

  update_levels = ({ amplitude }) ->
    value  = Math.round(amplitude * 96) + 32 # To get close to the right RGB range...
    colour = "rgb(#{value}, #{128 + value}, 0)"
    status.style.color = colour

  indicator = (status) ->
    # Do we need to deregister this as well?
    player.events.on 'current_level', update_levels

  try
    for await _update from self
      yield div '.play_pause_button.audio_status', ->
        a '.play_button', title: 'Click to select the audio channel', ->
          span '.icons.indicator'
        a '.stop_button.wave_status',
          title: 'Click to stop the audio stream'
        , ->
          span '.icons.idle', 'crank-ref': indicator
  finally
    player.events.off 'current_level', update_levels