import DisplayStatus    from './display_status'
import ChannelList      from './channel_list'
import VolumeSlider     from './volume_slider'
import ConnectionState  from './connection_state'
import StatusIcons      from './status_icons'
import Unsupported      from './unsupported'
import { css_class }    from '../lib/util'
import { crel, div }    from 'teact'

export default PlayerWidget = ({ player }) ->
  self = @
  player.events.on 'refresh', -> do self.refresh

  self.addEventListener 'click', ({ target }) ->
    { single } = player.state
    if single
      play = target.closest '.play_button, .stopped .status'
      stop = target.closest '.stop_button'
      switch
        when stop
          do player.stop_audio
        when play
          player.play_channel single
    else
      play = target.closest 'li.channel .item'
      menu = target.closest '.play_button, .status'
      stop = target.closest 'li.stop, .play_pause_button'
      switch
        when menu
          do player.toggle_menu
        when stop
          do player.stop_audio
        when play
          label = play.dataset.channel
          player.state.expanded = false
          player.play_channel label

  [ 'change', 'input' ].forEach (action) ->
    self.addEventListener action, ({ target }) ->
      if target.matches '.volume_slider'
        player.change_volume target.value

  for await _update from self
    { channels } = player
    { volume, active, supported, expanded, status, single, last_proto } = player.state
    if supported
      selected = channels.find (channel) -> channel.label is active
      styling = css_class 'audio_player', 'supported',
        ('activated' if expanded)
        (if active then 'playing' else 'stopped')
      yield div class: styling, ->
        crel StatusIcons, { player }
        crel DisplayStatus, { selected, active, protocol: last_proto }
        crel ChannelList, { active, channels } unless single
        crel VolumeSlider, { volume }
        crel ConnectionState, { active, status }
    else
      yield crel Unsupported