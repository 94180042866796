import { a, li, span, small, text, ul } from 'teact'
import { DELAYS, css_class } from '../lib/util'

export default ChannelList = ({ active, channels }) ->
  ul ->
    channels.forEach (channel) ->
      li '.channel',
        class: css_class(channel.label, 'active' if active is channel.label)
      , ->
        a '.item',
          data:
            channel: channel.label
        , ->
          span '.icons.play', class: DELAYS[channel.lag]
          text channel.name + ' '
          small ->
            if channel.lag > 10
              text '(' + String(channel.lag / 60) + ' min delay)'
            else
              text '(realtime)' if channel.lag
    li '.stop', ->
      a ->
        span '.icons.stop'
        text 'Stop audio'